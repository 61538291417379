.table-actions-container {
  display: flex;
  flex-direction: row;
  gap: 0;
}

.styled-table {
  border-collapse: separate;
  border-spacing: 0;
  margin: 25px 0;
  font-size: 0.9em;
  width: 100%;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  overflow: hidden;
}

.styled-table th:first-child,
.styled-table td:first-child {
  border-left: none;
}

.styled-table th,
.styled-table td {
  padding: 12px 15px;
}

.styled-table thead {
  background-color: #243b55;
  color: #ffffff;
  text-align: center;
}

.styled-table tbody tr {
  border-bottom: 1px solid #dddddd;
  text-align: center;
}

.styled-table tbody tr:nth-of-type(even) {
  background-color: #f3f3f3;
}

.styled-table tbody tr:hover {
  background-color: #e0e0e0;
}

.styled-table tbody td:last-of-type {
  text-align: center;
}

.tooltip-text {
  position: relative;
  cursor: pointer;
  margin-right: 10px;
}

.tooltip-text:hover::before {
  content: attr(data-tooltip);
  position: absolute;
  top: -30px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #333;
  color: #fff;
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 0.8rem;
  z-index: 1000;
}

.tooltip-text:hover::after {
  content: "";
  position: absolute;
  top: -10px;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 10px solid #333;
}

.table-actions-container button {
  background-color: #243b55;
  border: none;
  cursor: pointer;
  margin: 0;
  padding: 4px 8px;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.icon-button {
  background-color: transparent!important;
  border: none;
  cursor: pointer;
  margin: 0;
  padding: 0!important;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.icon-button:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.icon-button:active {
  background-color: rgba(0, 0, 0, 0.1);
}

.icon-red {
  color: #e74c3c;
}

.icon-green {
  color: #2ecc71;
}

.icon-blue {
  color: #3498db;
}

.icon-primary {
  color: #243b55;
}

.icon-yellow {
  color: #f39c12;
}

.icon-gray {
  color: #95a5a6;
}

.paginator {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 20px;
}

.paginator button {
  margin: 0 8px;
  display: flex;
  width: 32px;
  padding: 4px 8px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: #243b55;
  text-align: center;
  font-family: "Red Hat Text";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  border: none;
  cursor: pointer;
  background-color: transparent;
}

.paginator button.active {
  color: #fff;
  font-weight: 500;
  border-radius: 4px;
  background-color: #243b55;
}
