.document-card {
  border: 1px solid #ddd;
  padding: 16px;
  margin: 8px;
  display: inline-block;
  vertical-align: top;
  max-width: 150px;
}

.document-card img {
  max-width: 100%;
  height: auto;
}

.document-card-footer {
  margin-top: 16px;
  display: flex;
  justify-content: space-between;
}
