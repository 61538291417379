* {
  box-sizing: border-box;
}

body,
html {
  margin: 0;
  padding: 0;
  height: 100%;
}

body {
  font-family: "Roboto", sans-serif;
}

h1,
h2,
h3 {
  font-weight: 500; /* Medium */
}

strong,
b {
  font-weight: 700; /* Bold */
}

a {
  text-decoration: none;
  color: #0a205e;
  margin-bottom: 10px;
  display: inline-block;
}

h1 {
  font-weight: 500;
  margin-bottom: 30px;
}
