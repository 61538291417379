.custom-input {
  border: 1px solid #243b55;
  padding: 10px;
  border-radius: 4px;
  width: 100%;
  font-size: 16px;
  transition: border-color 0.3s;
}

.custom-input:focus {
  border-color: #007bff;
  outline: none;
  box-shadow: none;
}

select {
  background-color: #fff;
  color: #243b55;
  padding: 10px 10px;
  border: 1px solid #243b55;
  border-radius: 4px;
  cursor: pointer;
  width: 100%;
  appearance: none; /* Esto quitará el estilo por defecto en algunos navegadores */
}

.react-datepicker-wrapper {
  width: 100%;
}
