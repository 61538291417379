.login-container {
  display: flex;
  width: 100vw;
  height: 100vh;
}

.left-col {
  width: 30%;
  padding: 50px;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.login-logo {
  height: 48x;
  width: 200px;
  margin-bottom: 16px;
}

.right-col {
  width: 70%;
  background-color: #0a205e;
}

.login-container div input[type="text"],
.login-container div input[type="password"] {
  width: 100%;
  padding: 15px;
  margin-bottom: 15px;
  border: 1px solid #d0d0d0;
  border-radius: 5px;
  background-color: #f6f6f6;
  transition: all 0.3s;
}

input[type="text"]:focus,
input[type="password"]:focus {
  border: 2px solid #0a205e;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  background-color: white;
  outline: none;
}

button {
  padding: 15px;
  width: 100%;
  background-color: #0a205e;
  border: none;
  border-radius: 5px;
  color: white;
  cursor: pointer;
  font-weight: 500;
  margin-bottom: 15px;
}

button:disabled {
  background-color: #a1a9b1;
  color: #e0e0e0;
  cursor: not-allowed;
  opacity: 0.7;
}


/* Estilos para el contenedor */
.password-container {
  position: relative;
  display: inline-block;
  width: 100%;
}

.password-input {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
  width: 200px;
}

.toggle-password {
  position: absolute;
  right: 16px;
  top: 10px;
  width: 24px;
  border: none;
  background: transparent;
  cursor: pointer;
  font-size: 14px;
  color: #333;
}

.toggle-password:hover {
  color: #666;
  background-color: transparent;
}

.error-message {
  color: red;
  margin-bottom: 10px;
}
