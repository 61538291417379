.container {
  width: 100%;
  height: 100vh;
  margin: 0 auto;
  padding: 0;
  background-color: #e9eef7;
}

.layout-container {
  height: calc(100vh - 56px);
}

.header {
  width: 100%;
  position: fixed;
  top: 0;
  background-color: #fff;
  height: 56px;
  display: flex;
  padding: 0 20px;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  z-index: 999;
}

.header img {
  height: 30px;
}

.user-info {
  background-color: #051936;
  height: 32px;
  width: 32px;
  border-radius: 50%;
  color: #FFF;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.main-content {
  height: 100vh;
  width: 100%;
  padding: 20px;
  padding-top: 56px !important;
  padding-left: 260px;
}

.search-container {
  display: flex;
  justify-content: flex-end;
  flex: 0.9;
}

.search-container input {
  padding: 10px 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  width: 100% !important;
  transition: border-color 0.3s;
}

.search-container input:hover {
  border-color: #007bff;
}

.search-container input:focus {
  border-color: #007bff;
  outline: none;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

.form-container {
  position: relative;
}

.form-filter-container {
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: row;
  gap: 16px;
}

.form-filter-container button {
  padding: 12px 16px !important;
  margin: 0 !important;
  flex: 0.1;
}

.form-content {
  transition: opacity 0.3s, max-height 0.5s;
  opacity: 0;
  max-height: 0;
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.form-content.show {
  margin-top: 8px;
  opacity: 1;
  max-height: 500px;
}

.form-content.row {
  flex-wrap: nowrap;
  flex-direction: row;
  margin-top: 8px;
  opacity: 1;
  max-height: 500px;
}
.form-content.row .react-datepicker-wrapper {
  flex: 0.85!important;
}
.form-content.row button {
  flex: 0.15!important;
  padding: 0!important;
  height: 40px;
}

.form-content .column {
  flex: 1;
}

.form-content input {
  display: block;
  width: 100%;
  padding: 10px;

  border: 1px solid #ccc;
  border-radius: 4px;
}

.form-content button {
  margin: 0 !important;
}

button {
  background-color: #243b55;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: 100%;
}

button:hover {
  background-color: #0056b3;
}

.modal-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  max-width: 600px;
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.modal-close-button {
  background-color: transparent;
  margin-top: 8px;
  color: #051936;
}

.modal-close-button:hover {
  background-color: #0056b3;
  color: #fff !important;
}
