.sidebar {
  background-color: #051936;
  top: 56px;
  width: 250px;
  height: calc(100vh - 56px);
  overflow-y: auto;
  position: fixed;
}

.sidebar ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.sidebar ul li a {
  color: #8997ac;
  text-decoration: none;
  font-weight: 500;
  display: flex;
  align-items: center;
  padding: 12px 16px;
  margin: 0 !important;
}

.sidebar ul li a:hover {
  background-color: #1e2a38;
}

.sidebar ul li a.active {
  background-color: #0e3361;
  position: relative;
  color: #fff;
}

.sidebar ul li a.active::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 4px;
  background-color: #125bce;
}

.logout-button {
  position: absolute;
  bottom: 0;
  left: 16px;
  width: calc(100% - 32px);
  background-color: #ff4d4d;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
}

.logout-button:hover {
  background-color: #ff3333;
}